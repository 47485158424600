import React from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";
import BtnContent from "../Btn/components/BtnContent";

// ** Custom Styles
import { resolveProps, marginable } from "styles/styledComponentsMixins";

// #####################################################

const StyledButtonDropdownSelector = styled(
	({ isOpen, layerSide, ...props }) => (
		<Button {...resolveProps(props, marginable)} />
	)
)(({ isOpen, layerSide }) => {
	return css`
		position: relative;
		display: inline-block;
		border-radius: var(--dz-sys-button-border-radius);
		font-size: 1rem;
		padding: 0.4rem 1rem;
		height: 35px;
		width: fit-content;
		line-height: 1.5rem;
		background-color: var(--dz-sys-button-dropdown-background-color);
		box-shadow: var(--dz-sys-input-shadow);
		color: var(--dz-sys-button-secondary-text-color);
		border: 1px solid var(--dz-sys-button-secondary-background-color);

		${marginable.css}

		&:focus {
			background-color: var(--dz-sys-button-dropdown-background-color);
		}

		&:hover {
			background-color: var(
				--dz-sys-button-secondary-background-color-hover
			);
		}

		&:active {
			background-color: var(--dz-sys-button-dropdown-background-color);
		}

		${isOpen &&
		css`
			background-color: var(--dz-sys-button-dropdown-background-color);
			${layerSide === "top"
				? css`
						border-top-left-radius: 0;
						border-top-right-radius: 0;
				  `
				: css`
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
				  `}

			&:focus {
				background-color: var(
					--dz-sys-button-dropdown-background-color
				);
			}

			&:active {
				background-color: var(
					--dz-sys-button-dropdown-background-color
				);
			}
		`}
	`;
});

// #####################################################

export const ButtonDropdownSelector = ({
	isOpen,
	textLeft,
	iconType,
	iconTypeActive,
	icon,
	iconOnly,
	iconSize,
	iconComponent,
	svgIconComponent,
	svgIconComponentActive,
	dropdown,
	alignItemsStart,
	noPointerEffect,
	label,
	children,
	layerSide,
	...rest
}) => {
	const textContent = children || label;

	return (
		<StyledButtonDropdownSelector
			isOpen={isOpen}
			layerSide={layerSide}
			{...rest}
		>
			<BtnContent
				noPointerEffect={noPointerEffect}
				active={isOpen}
				textLeft={textLeft}
				textContent={textContent}
				iconType={iconType}
				iconTypeActive={iconTypeActive}
				icon={icon}
				iconOnly={iconOnly}
				iconSize={iconSize}
				iconComponent={iconComponent}
				svgIconComponent={svgIconComponent}
				svgIconComponentActive={svgIconComponentActive}
				dropdown={dropdown}
				alignItemsStart={alignItemsStart}
			/>
		</StyledButtonDropdownSelector>
	);
};

// #####################################################
