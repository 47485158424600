// ** React Imports
import { generatePath, useHistory } from "react-router-dom";

// ** Redux
import { useSelector } from "react-redux";

// ** Custom Components
import Block from "components/layout/Block";
import Text from "components/typography/Text";

// #####################################################

const UserGalleryViewTypeSelectorContent = ({ close, items = [], albumId }) => {
	const itemsToDisplay = items.filter((item) => !item.isActive);

	const history = useHistory();

	const defaultPath = "/users/:login/gallery/album/:album";

	const userLogin = useSelector((state) => state.user.main.login);

	// #####################################################

	return (
		<Block flex column justifyBetween fullHeight>
			{/* Menu Główne */}
			{itemsToDisplay.map((item) => (
				<Block
					key={item.key}
					py={2}
					px={4}
					style={{
						cursor: "pointer",
					}}
					onClick={() => {
						history.push({
							pathname: generatePath(defaultPath, {
								login: userLogin?.toLowerCase(),
								album: albumId,
							}),
							search:
								item.key === "photos"
									? "?sort=photos"
									: item.key === "videos"
									? "?sort=videos"
									: "",
							state: { forceReload: true },
						});
						close();
					}}
				>
					<Text>
						{item.label} <Text bold>{item.value}</Text>
					</Text>
				</Block>
			))}
		</Block>
	);
};

// #####################################################

export default UserGalleryViewTypeSelectorContent;

// #####################################################
