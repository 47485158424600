// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledUl = styled.ul`
	list-style-type: "- ";
	margin-left: 1.25rem;
	margin-top: 0.25rem;
`;

// #####################################################
