// ** React Imports
import {
	useEffect,
	useContext,
	useRef,
	useCallback,
	useState,
	useMemo,
} from "react";
import { useLocation, useHistory } from "react-router-dom";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { getProfileAlbumGalleries } from "store/modules/user";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Masonry from "components/layout/Masonry/Masonry";
import UserGalleryViewTypeSelector from "./UserGalleryViewTypeSelector";
import Paragraph from "components/typography/Paragraph";
import MasonryImage from "components/layout/Masonry/MasonryImage";
import MasonryVideo from "components/layout/Masonry/MasonryVideo";
import Block from "components/layout/Block";
import Text from "components/typography/Text";
import Loader from "components/layout/Loader";
import ScrollToTop from "components/global/ScrollToTop";
import Icon from "components/global/Icon";

// ** Custom Hooks
import useQuery from "hooks/useQuery";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";
import authContext from "contexts/authContext";

// #####################################################

const columns = {
	phone: 3,
	tablet: 3,
	desktop: 4,
	giant: 4,
};

// #####################################################

const Album = ({
	globalUserId,
	route,
	getProfileAlbumGalleries,
	current_album,
}) => {
	const { t } = useTranslation(["userProfile"]);

	const user = useSelector((state) => state.user.main);
	const isAuth = useContext(authContext);

	const location = useLocation();
	const history = useHistory();

	// ** Odczytanie parametrów z query
	const query = useQuery();

	const [sort, setSort] = useState(undefined);
	const [mediaRendered, setMediaRendered] = useState(false);

	const { albumId } = route;

	const { isPhone } = useContext(deviceTypeContext);

	const isMe = user.id === globalUserId;

	const observer = useRef();
	const lastElementRef = useCallback(
		(node) => {
			if (current_album.loading) return;
			if (current_album[albumId]?.data.length === 0) return;
			if (loadedMedia >= totalMedia) return null;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					loadMorePhotos();
				}
			});
			if (node) observer.current.observe(node);
		},
		[current_album.loading]
	);

	const emergencyObserver = useRef();
	const emergencyTrigger = useCallback(
		(node) => {
			if (current_album.loading) return;
			if (!mediaRendered) return;
			if (loadedMedia >= totalMedia) return null;
			if (emergencyObserver.current)
				emergencyObserver.current.disconnect();
			emergencyObserver.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					loadMorePhotos();
				}
			});
			if (node) emergencyObserver.current.observe(node);
		},
		[current_album.loading]
	);

	useEffect(() => {
		if (
			(albumId !== undefined && !current_album[albumId]) ||
			sort !== query?.sort ||
			location.state?.forceReload
		) {
			getProfileAlbumGalleries(
				albumId,
				user.id,
				1,
				null,
				isAuth,
				query?.sort ?? "any",
				true
			);

			if (location.state?.forceReload) {
				history.replace({ ...location, state: null });
			}
		}
		setSort(query?.sort);
	}, [query?.sort, albumId, location.state?.forceReload]);

	useEffect(() => {
		setMediaRendered(false);
	}, [current_album.loading]);

	const loadedMedia = current_album[albumId]?.data.length ?? 0;

	const pagination = current_album[albumId]?.pagination ?? {
		totalPages: 1,
		currentPage: 1,
	};

	const videos = current_album[albumId]?.videos ?? 0;
	const photos = current_album[albumId]?.photos ?? 0;

	const totalMedia = current_album[albumId]?.total ?? 0;

	const hashMedia = current_album[albumId]?.hash ?? null;

	const items = useMemo(() => {
		return current_album[albumId]?.data.map((media) => {
			if (media.media_type === "photo") {
				return (
					<MasonryImage
						{...media}
						login={user?.login?.toLowerCase()}
						album={albumId}
						images={media.media}
						key={media.id}
						isUserGallery
						bottomAsLinkToMedia
					/>
				);
			} else if (media.media_type === "video") {
				return (
					<MasonryVideo
						{...media}
						thumbs={media.media.thumbs}
						login={user?.login?.toLowerCase()}
						key={media.id}
						withoutLazyLoading={false}
						album={albumId}
						isUserGallery
						bottomAsLinkToMedia
					/>
				);
			}
		});
	}, [current_album[albumId]?.data]);

	const loadMorePhotos = () => {
		getProfileAlbumGalleries(
			albumId,
			user.id,
			pagination.currentPage + 1,
			hashMedia,
			isAuth,
			query?.sort ?? "any"
		);
	};

	const ablumName = useMemo(() => {
		const icon =
			current_album.isPrivate &&
			(user.status.friendStatus === 3 || isMe) ? (
				<Icon>unlock</Icon>
			) : (
				current_album.isPrivate && <Icon>lock</Icon>
			);

		if (current_album?.name) {
			if (current_album.isPrivate) {
				return (
					<>
						{icon} {current_album.name}
					</>
				);
			}
			return current_album.name;
		}

		return current_album.isPrivate ? (
			<>
				{icon} {t("userpanel:media:album:mainPrivate")}
			</>
		) : (
			t("userpanel:media:album:main")
		);
	}, [current_album?.name, current_album?.isPrivate]);

	const renderNavigation = () => {
		return (
			<Block flex justifyBetween alignCenter mb={3} withColumnGap>
				<Block full style={{ minWidth: 0 }}>
					<Text
						bold
						textNowrap
						style={{
							textOverflow: "ellipsis",
							display: "block",
							overflow: "hidden",
						}}
					>
						{t("common:album")}: {ablumName}
					</Text>
				</Block>

				<UserGalleryViewTypeSelector
					albumId={albumId}
					videos={videos}
					photos={photos}
				/>
			</Block>
		);
	};

	// #####################################################

	if (loadedMedia === 0 && totalMedia === 0 && !current_album.loading) {
		return (
			<Block mx={isPhone && 4}>
				{renderNavigation()}
				<Block>
					<Paragraph size="1rem" mb={0}>
						{query?.sort === "videos"
							? t("userProfile:noVideos")
							: t("userProfile:noPhotos")}
					</Paragraph>
				</Block>
			</Block>
		);
	}

	return (
		<>
			<ScrollToTop />

			<Block mx={isPhone && 4}>{renderNavigation()}</Block>

			{current_album.loading && !loadedMedia > 0 && (
				<Block flex justifyCenter mt={2} mb={2}>
					<Loader />
				</Block>
			)}

			{current_album[albumId]?.data.length > 0 ? (
				<Masonry columns={columns} elementRef={lastElementRef}>
					{items}
				</Masonry>
			) : null}

			{current_album.loading && loadedMedia < totalMedia && (
				<Block flex justifyCenter mt={2} mb={2}>
					<Loader />
				</Block>
			)}
			{!current_album.loading &&
				loadedMedia < totalMedia &&
				current_album[albumId]?.data &&
				mediaRendered && (
					<div
						ref={emergencyTrigger}
						style={{
							height: "1px",
							pointerEvents: "none",
						}}
					></div>
				)}
		</>
	);
};

// #####################################################

const mapStateToProps = ({
	user: {
		galleries: { current_album },
	},
	global: {
		user: { id },
	},
}) => ({ current_album, globalUserId: id });

export default connect(mapStateToProps, { getProfileAlbumGalleries })(Album);

// #####################################################
