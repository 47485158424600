// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Components
import Block from "components/layout/Block";
import { ButtonDropdownSelector } from "components/layout/Button/ButtonDropdownSelector";
import Text from "components/typography/Text";

// #####################################################

const UserGalleryViewTypeSelectorButton = ({
	triggerProps,
	toggle,
	isOpen,
	items = [],
	layerSide,
	layerWidth,
}) => {
	const { t } = useTranslation(["common", "pages"]);

	const activeItem = items.find((item) => item.isActive);

	// #####################################################

	return (
		<div {...triggerProps}>
			<ButtonDropdownSelector
				style={{
					width: layerWidth,
					minWidth: "fit-content",
				}}
				onClick={() => {
					if (typeof toggle === "function") {
						toggle();
					}
				}}
				isOpen={isOpen}
				aria-haspopup="true"
				label={t("pages:search")}
				dropdown
				textLeft
				layerSide={layerSide}
			>
				<Block flex withColumnGap>
					<Text>
						{activeItem?.label}
						<Text bold ml={1}>
							{activeItem?.value}
						</Text>
					</Text>
				</Block>
			</ButtonDropdownSelector>
		</div>
	);
};

export default UserGalleryViewTypeSelectorButton;
