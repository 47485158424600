// ** React Imports
import { useContext, useEffect } from "react";

// ** Redux & Store & Actions
import { useSelector, useDispatch } from "react-redux";
import { asyncConnect } from "redux-connect";
import { updateStatisticsSite } from "store/modules/global";
import { getAppJson } from "store/modules/mainPages";

// ** Third Party Components
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";

// ** Custom Components
import Block from "components/layout/Block";
import { ButtonPrimary } from "components/layout/Button/ButtonPrimary";
import Text from "components/typography/Text";
import { CardWrapper } from "components/layout/Card/CardWrapper";
import { CardBody } from "components/layout/Card/CardBody";
import { CardTitle } from "components/layout/Card/CardTitle";
import { CardsBlockWrapper } from "components/layout/Card/CardsBlockWrapper";
import Heading from "components/typography/Heading";
import Tooltip from "components/layout/Tooltip";
import Suspension from "components/suspension";
import Paragraph from "components/typography/Paragraph";
import EmptyBlock from "components/layout/Block/BlockEmpty";
import ScrollToTop from "components/global/ScrollToTop";

// ** Custom Hooks
import useQuery from "hooks/useQuery";
import useOpenGraphMetaTags from "hooks/useOpenGraphMetaTags";
import usePWAInstalable from "hooks/usePWAInstalable";

// ** Styled Components
import { StyledUl } from "./styles";

const AppScreens = loadable(() => import("./components/AppScreens"), {
	ssr: false,
});

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Contexts
import DeviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const StyledBlockContainer = styled(Block)`
	width: 300px;
	height: 700px;

	@media (max-width: ${breakpoints.phone - 1}px) {
		width: 90%;
		margin: 0 auto;
		padding-right: 0;
		max-width: 300px;
		height: unset;
	}
`;

// #####################################################

const App = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation(["pages", "contact", "userpanel"]);
	const title = t("pages:app");

	const { isDesktop } = useContext(DeviceTypeContext);

	// ** Odczytanie parametrów z query
	const query = useQuery();

	const isClient = useSelector((state) => state.global.isClient);

	// ** Dane strony: Application
	const { loaded, loading, error } = useSelector(
		(state) => state.mainPages.app
	);

	// ** Efekt pobierajacy dane strony w przypadku gdy nie zostały
	// ** załadowane wcześniej (np. przez SSR)
	useEffect(() => {
		if (!loading && (!loaded || (loaded && error))) {
			dispatch(getAppJson());
		}
	}, []);

	// ** Open Graph Meta Tags
	const ogMetaTags = useOpenGraphMetaTags();

	// ** check PWA is Installable
	const { installStatus, handleOnInstall } = usePWAInstalable();

	let isInstalledPWA = false;

	if (typeof localStorage !== "undefined") {
		isInstalledPWA = localStorage.getItem("isInstallSuccessPWA");
	}

	// #####################################################

	return (
		<>
			{/* Sekcja HEAD */}
			<Helmet title={title}>{ogMetaTags}</Helmet>

			<Suspension />
			<ScrollToTop />

			<CardsBlockWrapper>
				{/* Główna zawartość strony */}
				<CardWrapper>
					<CardTitle>
						<Heading type="h2">{title}</Heading>
					</CardTitle>
					<CardBody>
						<Block flex column={!isDesktop}>
							<Block flex column mb={8}>
								<Block>
									{query.redirectFromAndroidApp && (
										<Block flex flexWrap mb={4}>
											<Text bold color="red">
												{t(
													"contact:app.redirectFromApp"
												)}
											</Text>
										</Block>
									)}
									<Paragraph>
										<Text>{t("contact:app.pwa.info")}</Text>
									</Paragraph>
									<Paragraph mt={5}>
										<Text>
											{t(
												"contact:app.pwa.instalation.info"
											)}
											:
										</Text>
										<StyledUl>
											<li>
												{t(
													"contact:app.pwa.instalation.chrome"
												)}
											</li>
											<li>
												{t(
													"contact:app.pwa.instalation.firefox"
												)}
											</li>
											<li>
												{t(
													"contact:app.pwa.instalation.iOS"
												)}
											</li>
										</StyledUl>
									</Paragraph>

									<Block flex>
										{installStatus?.isInstallAllowed ||
										installStatus?.isInstallWatingConfirm ? (
											<ButtonPrimary
												onClick={handleOnInstall}
											>
												{t("contact:app.downloadPWA")}
											</ButtonPrimary>
										) : (
											<Tooltip
												text={
													isInstalledPWA
														? t(
																`userpanel:settings.preferences.notifications.appIsInstaled`
														  )
														: t(
																`userpanel:settings.preferences.notifications.appCanNotInstall`
														  )
												}
											>
												<ButtonPrimary
													disabled
													addBoxShadow
												>
													{t(
														"contact:app.downloadPWA"
													)}
												</ButtonPrimary>
											</Tooltip>
										)}
									</Block>
								</Block>
							</Block>

							<Block flex justifyCenter flexWrap px={8}>
								<StyledBlockContainer>
									{isClient && <AppScreens />}
								</StyledBlockContainer>
							</Block>
						</Block>
					</CardBody>
				</CardWrapper>

				<EmptyBlock />
			</CardsBlockWrapper>
		</>
	);
};

// #####################################################

export default asyncConnect([
	{
		key: "appDataFromSSR",
		promise: (res) => {
			const {
				store: { dispatch, getState },
			} = res;
			const state = getState();
			const isAuthFromServer = state.auth.isAuthFromServer;

			const isSSR = typeof window === "undefined";

			if (isSSR && !isAuthFromServer) {
				return dispatch(getAppJson()).then((res) => {
					const { counters } = res?.result || {};
					dispatch(updateStatisticsSite(counters));
					return res?.result?.data ?? null;
				});
			}
		},
	},
])(App);
