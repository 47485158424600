// ** Third Party Components
import styled from "styled-components";

// ** Utils & Helpers
import DateTime from "utils/datetime";

// #####################################################

const TimePart = styled("span")`
	white-space: nowrap;
`;

// #####################################################

const DatePresenter = ({ date: rawDate, newline, dateOnly, comma = false }) => {
	const date = Number.isInteger(rawDate)
		? new Date(rawDate * 1000).toISOString()
		: rawDate;

	const dt = new DateTime(date);

	const [datePart, timePart] = dt.format("DD.MM.YYYY HH:mm").split(" ");

	// #####################################################

	if (!date) {
		return <time>&nbsp;</time>;
	}

	return (
		<time>
			{datePart && (
				<TimePart>
					{datePart}
					{comma && ", "}
				</TimePart>
			)}
			{datePart && timePart && newline === true ? <br /> : " "}
			{!dateOnly && timePart && <TimePart>{timePart}</TimePart>}
		</time>
	);
};

// #####################################################

DatePresenter.displayName = "DatePresenter";

export default DatePresenter;
