// ** React Imports
import { useContext, useEffect } from "react";
import { generatePath, matchPath, useLocation } from "react-router-dom";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { getProfileAlbums } from "store/modules/user";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import { renderRoutes } from "react-router-config";
import styled, { css } from "styled-components";
import { breakpoints } from "styles/grid";

// ** Custom Components
import { CardWrapper } from "components/layout/Card/CardWrapper";
import { CardBody } from "components/layout/Card/CardBody";
import { CardTitle } from "components/layout/Card/CardTitle";
import { CardsBlockWrapper } from "components/layout/Card/CardsBlockWrapper";
import Block from "components/layout/Block";
import Icon from "components/global/Icon";
import Text from "components/typography/Text";
import EmptyBlock from "components/layout/Block/BlockEmpty";
import Tooltip from "components/layout/Tooltip";
import Link from "components/layout/Link";

// ** Images Components
import Album from "./components/Album";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// ** Contexts
import authContext from "contexts/authContext";

// #####################################################

const StyledActiveElement = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;
	background-color: transparent;
	z-index: 4;
	position: relative;

	${({ isActive }) =>
		isActive &&
		css`
			border: 3px solid
				${({ theme: { isDarkMode } }) => (isDarkMode ? "#FFF" : "#000")};
		`}
`;

const StyledImage = styled.img`
	display: flex;
	max-height: 100%;
	max-width: 100%;

	${({ isFilling }) =>
		isFilling &&
		css`
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		`};

	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`}

	${({ zoom }) =>
		zoom &&
		css`
			transition: transform 100ms;
			transform: scale(1);

			&:hover,
			&:focus {
				transform: scale(1.05);
			}
		`}

	${({ isCover }) =>
		isCover &&
		css`
			object-fit: cover;
		`}

		
	${({ isActive }) =>
		isActive &&
		css`
			box-sizing: border-box;

			border: 3px solid transparent;
		`}
`;

const GridWrapper = styled.div`
	display: grid;
	gap: var(--grid-gap);

	// 1340+
	grid-template-columns: repeat(8, minmax(0, 1fr));
	// 1050-1339
	@media (max-width: ${breakpoints.giant - 1}px) {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
	// 600-1049
	@media (max-width: ${breakpoints.desktop - 1}px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	// 0-599
	@media (max-width: ${breakpoints.phone - 1}px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	/* grid-template-columns: repeat(auto-fill, 116px); */
	/* grid-template-columns: repeat(auto-fill, minmax(116px)); */
	width: 100%;
`;

const AlbumContainer = styled(Link)`
	position: relative;
	display: inline-grid;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	color: var(--dz-ref-color-white);
	padding-bottom: 100%;
	overflow: hidden;
	background-color: var(--dz-ref-color-gray100);

	&:visited {
		color: var(--dz-ref-color-white);
	}

	${({ isActive }) =>
		isActive &&
		css`
			font-weight: bold;
		`}
`;

const AlbumContainerDisabled = styled(Block)`
	cursor: pointer;
	position: relative;
	display: inline-grid;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	color: var(--dz-ref-color-white);
	padding-bottom: 100%;
	overflow: hidden;
	background-color: var(--dz-ref-color-gray100);

	&:visited {
		color: var(--dz-ref-color-white);
	}

	${({ isActive }) =>
		isActive &&
		css`
			font-weight: bold;
		`}
`;

const AlbumContainerContent = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`;

const AlbumDataContainer = styled.div`
	position: absolute;
	bottom: 0;
	padding: 0.5rem 0.5rem 0.25rem;
	z-index: 3;
	font-size: 11.9px;
	display: flex;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 100%
	);
	svg {
		color: var(--color-whiteTrueAlways-darken-200);
	}
`;

const Galleries = ({
	globalUserId,
	getProfileAlbums,
	albums_list,
	match: {
		params: { login },
	},
	route: { path: userGalleryPath },
	language,
}) => {
	const user = useSelector((state) => state.user.main);

	const isAuth = useContext(authContext);
	const { t } = useTranslation(["userProfile", "pages"]);
	const location = useLocation();

	const isMe = user.id === globalUserId;
	const pathname = location?.pathname ?? "";

	const allowPrivateMedia =
		user.status.friendStatus === 3 || isMe ? true : null;

	useEffect(() => {
		if (user.loaded) {
			getProfileAlbums(user.id, isAuth, allowPrivateMedia);
		}
	}, [user.loaded, language]);

	const routes = Array.isArray(albums_list?.data)
		? albums_list.data.map((album) => ({
				component: Album,
				path: `${userGalleryPath}/album/${album?.id}`,
				albumId: album?.id,
				albumPrivate: album?.private,
				amount: album?.amount,
		  }))
		: [];

	const albumListFiltered = albums_list.data.filter((album) => {
		return album?.amount > 0;
	});

	let isSomeActive = user.status.friendStatus === 3 || isMe;

	if (!isSomeActive) {
		albumListFiltered.map((album) => {
			if (!album.private) {
				isSomeActive = true;
				return true;
			}
		});
	}

	/**
	 * Dodanie głównej ścieżki 'users/:login/images'
	 * do renderowania,
	 */
	let defaultIndex = null;

	if (albumListFiltered.length) {
		albums_list.data.find((album, index) => {
			if (album.id === albumListFiltered[0].id) {
				defaultIndex = index;
				return;
			}
		});

		routes.push({
			component: Album,
			exact: true,
			path: userGalleryPath,
			mainRoute: true,
			albumId: albumListFiltered[0]?.id,
			albumPrivate: albumListFiltered[0]?.private,
		});
	}

	const avatarDefaultType =
		user.sex === "transsexual"
			? "t"
			: user.sex === "couple"
			? "p"
			: user.sex === "woman"
			? "k"
			: "m";

	const defaultAvatar = `https://imgcdn.datezone.com/static/img/layout/avatar/avatar_${avatarDefaultType}_big.jpg?v=2`;

	// #####################################################

	return (
		<CardsBlockWrapper>
			<CardWrapper>
				{albums_list.loaded && albums_list.data.length > 0 && (
					<CardTitle>
						<Text bold>{t("pages:userpanelAlbums")}</Text>
					</CardTitle>
				)}
				<CardBody noPaddingXmobile={albums_list.data.length > 0}>
					{albums_list.loaded && albums_list.data.length === 0 && (
						<Block mt={2} my={2}>
							<Text>{t("userProfile:noGalleries")}</Text>
						</Block>
					)}
					{albums_list.loaded && albums_list.data.length > 0 && (
						<Block flex alignBaseline>
							<GridWrapper>
								{albums_list.data.map((route, index) => {
									if (route.amount === 0) {
										return null;
									}

									const bgImage = route.image
										? route.image.type === "photo"
											? route.image.media.square
											: route.image.media.poster_square
										: defaultAvatar;

									const to = generatePath(
										`${userGalleryPath}/album/:album`,
										{
											login: login?.toLowerCase(),
											album: route.id,
										}
									);

									const isDisabled =
										route.private &&
										user.status.friendStatus !== 3 &&
										!isMe;

									const isActive =
										!isDisabled &&
										matchPath(pathname, {
											path: userGalleryPath,
											exact: true,
										}) &&
										index === defaultIndex
											? true
											: !isDisabled &&
											  matchPath(pathname, {
													path: `${userGalleryPath}/album/${route.id}`,
											  });

									return (
										<Tooltip
											text={
												route.private &&
												user.status.friendStatus !==
													3 &&
												!isMe
													? t(
															"userProfile:tooltip:privateGallery"
													  )
													: ""
											}
											key={`albums-tooltip-${route.id}`}
										>
											{isDisabled ? (
												<AlbumContainerDisabled
													key={route.id}
													disabled={isDisabled}
												>
													<StyledImage
														src={bgImage}
														isBlurred={!isAuth}
														isFilling
														isCover
													/>
													<AlbumContainerContent>
														<AlbumDataContainer>
															<Block
																fullWidth
																flex
																justifyBetween
															>
																<Text
																	style={{
																		display:
																			"flex",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																		whiteSpace:
																			"nowrap",
																	}}
																>
																	<Text
																		style={{
																			overflow:
																				"hidden",
																			textOverflow:
																				"ellipsis",
																		}}
																		color="whiteAlways"
																	>
																		{route.private && (
																			<Text
																				mr={
																					1
																				}
																			>
																				<Icon>
																					lock
																				</Icon>
																			</Text>
																		)}{" "}
																		{
																			route.name
																		}
																	</Text>
																</Text>
																<Block
																	textNowrap
																	ml={1}
																>
																	<Text color="whiteAlways">
																		{pseudoFormat(
																			route.amount
																		)}
																	</Text>
																</Block>
															</Block>
														</AlbumDataContainer>

														<StyledActiveElement
															isActive={isActive}
														/>
													</AlbumContainerContent>
												</AlbumContainerDisabled>
											) : (
												<AlbumContainer
													noScroll
													isActive={isActive}
													to={
														isDisabled
															? "#"
															: {
																	pathname:
																		to,
																	state: {
																		forceReload: true,
																	},
															  }
													}
													key={route.id}
													disabled={isDisabled}
												>
													<StyledImage
														src={bgImage}
														isBlurred={!isAuth}
														isFilling
														isCover
														isActive={isActive}
													/>
													<AlbumContainerContent>
														<AlbumDataContainer>
															<Block
																fullWidth
																flex
																justifyBetween
															>
																<Text
																	style={{
																		display:
																			"flex",
																		overflow:
																			"hidden",
																		textOverflow:
																			"ellipsis",
																		whiteSpace:
																			"nowrap",
																	}}
																	color="whiteAlways"
																>
																	<Text
																		style={{
																			overflow:
																				"hidden",
																			textOverflow:
																				"ellipsis",
																		}}
																		color="whiteAlways"
																	>
																		{route.private &&
																		(user
																			.status
																			.friendStatus ===
																			3 ||
																			isMe) ? (
																			<Text
																				mr={
																					1
																				}
																			>
																				<Icon>
																					unlock
																				</Icon>
																			</Text>
																		) : (
																			route.private && (
																				<Text
																					mr={
																						1
																					}
																				>
																					<Icon>
																						lock
																					</Icon>
																				</Text>
																			)
																		)}{" "}
																		{
																			route.name
																		}
																	</Text>
																</Text>
																<Block
																	textNowrap
																	ml={1}
																	color="whiteAlways"
																>
																	<Text>
																		{pseudoFormat(
																			route.amount
																		)}
																	</Text>
																</Block>
															</Block>
														</AlbumDataContainer>

														<StyledActiveElement
															isActive={isActive}
														/>
													</AlbumContainerContent>
												</AlbumContainer>
											)}
										</Tooltip>
									);
								})}
							</GridWrapper>
						</Block>
					)}
				</CardBody>
			</CardWrapper>
			{albums_list.loaded &&
				albums_list.data.length > 0 &&
				isSomeActive && (
					<CardWrapper>
						<CardBody noPaddingXmobile>
							{renderRoutes(routes)}
						</CardBody>
					</CardWrapper>
				)}
			<EmptyBlock />
		</CardsBlockWrapper>
	);
};

// #####################################################

const mapStateToProps = ({
	user: {
		galleries: { albums_list },
	},
	global: {
		user: { id },
		language,
	},
}) => ({ albums_list, globalUserId: id, language });

export default connect(mapStateToProps, {
	getProfileAlbums,
})(Galleries);

// #####################################################
