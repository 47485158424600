// ** React
import { useMemo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Dropdown from "components/layout/Dropdown";
import UserGalleryViewTypeSelectorButton from "./UserGalleryViewTypeSelectorButton";
import UserGalleryViewTypeSelectorContent from "./UserGalleryViewTypeSelectorContent";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";
import useQuery from "hooks/useQuery";

// ** Contexts
import { DropdownTriggerContext } from "components/layout/Dropdown/DropdownTriggerContext";

// #####################################################
const UserGalleryViewTypeSelector = ({ albumId, videos, photos }) => {
	const { t } = useTranslation(["common", "pages"]);

	const language = useSelector((state) => state.global.language);

	// ** Odczytanie parametrów z query
	const query = useQuery();

	const { isOpen, close, toggle } = useOpenClose();

	const isClient = useSelector((state) => state.global.isClient);

	const useLayerOptions = {
		onOutsideClick: () => {
			typeof close === "function" && close();
		},
		placement: "bottom-start",
		triggerOffset: -1,
		overflowContainer: false,
		container: isClient ? "app" : "root",
	};

	const customLayerStyle = {
		overflowY: "auto",
		minWidth: "fit-content",
		zIndex: 999,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		border: "none",
		backgroundColor: "var(--dz-sys-button-dropdown-background-color)",
		boxShadow: "var(--dz-sys-input-shadow)",
	};

	const items = useMemo(() => {
		return [
			{
				label: `${t("common:selectAll2")}`,
				key: "all",
				isActive: query?.sort !== "photos" && query?.sort !== "videos",
				value: photos + videos,
			},
			{
				label: `${t("pages:images")}`,
				key: "photos",
				isActive: query?.sort === "photos",
				value: photos,
			},
			{
				label: `${t("pages:videos")}`,
				key: "videos",
				isActive: query?.sort === "videos",
				value: videos,
			},
		];
	}, [query?.sort, language, videos, photos]);

	// #####################################################

	return (
		<Dropdown
			isOpen={isOpen}
			close={(insideUseEffect) => {
				close(insideUseEffect);
			}}
			useLayerOptions={useLayerOptions}
			customLayerStyle={customLayerStyle}
			menuContent={() => (
				<UserGalleryViewTypeSelectorContent
					close={close}
					items={items}
					albumId={albumId}
				/>
			)}
			autoBroderAllDevices
		>
			<DropdownTriggerContext.Consumer>
				{({ triggerProps, layerSide, layerWidth }) => (
					<UserGalleryViewTypeSelectorButton
						triggerProps={triggerProps}
						isOpen={isOpen}
						toggle={toggle}
						items={items}
						layerWidth={isOpen ? layerWidth : "auto"}
						layerSide={layerSide}
					/>
				)}
			</DropdownTriggerContext.Consumer>
		</Dropdown>
	);
};

// #####################################################

export default UserGalleryViewTypeSelector;

// #####################################################
